import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import "./../../styles/404.css"
import SEO from "../../components/seo"

const DeepLink = () => {
  const [imageUrl, setImageUrl] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlPath = window.location.href
      let decodedUri = decodeURIComponent(urlPath)
      decodedUri = decodedUri.replace(/[\[\]]/g, "\\$&")
      let regex = new RegExp("cattleListingID" + "(=([^&#]*)|&|#|$)")
      let results = regex.exec(decodedUri)
      if (!results) return
      if (!results[2]) return
      let cattleListingID = results[2].replace(/\+/g, " ")

      fetchAdData(cattleListingID)
    }
  }, [])

  const fetchAdData = (cattleListingID, userID = "4815288705") => {
    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("userid", userID)
    myHeaders.append("token", "8faba40ffd9a4defa697f88ee79003fc")
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      `https://api.mooofarm.com/v2/cattle/Listing/${cattleListingID}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        let imageFile = result?.data[0].MediaGallery.filter(
          m => m.FileType == 0
        )
        let i = "Empty.png"
        if (imageFile.length > 0) {
          i = imageFile[0].FileName
        }
        let cloudinary_url = `https://res.cloudinary.com/mooofarm/image/fetch/https://mooofarmpubnubmediafiles.blob.core.windows.net/cattletrading/${i}`
        setImageUrl(cloudinary_url)
      })
      .catch(error => console.log("error", error))
  }

  return (
    <Layout>
      <SEO title="Trade Cattle on MoooFarm App" image={imageUrl} />
      <div className="not-found-container">
        <div className="display-flex-1 center-content">
          {/* <img src={imageUrl} className="not-found-image"></img> */}
          <h1 className="not-found-container-heading">Download MoooFarm App</h1>
          <button className="go-home-button">
            {
              <Link to="https://play.google.com/store/apps/details?id=com.moo">
                Go to Play Store
              </Link>
            }
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default DeepLink
